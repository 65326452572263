import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import { useTranslation } from 'react-i18next'

interface Props {}

function CareerSpecialist(props: Props) {
    const {} = props
    const { classes, cx } = useStyles()
    const { t } = useTranslation()

    return (
        <Box className={classes.container}>
            <Box className={cx(classes.column, classes.left)}>
                <img src="https://images.ctfassets.net/7thvzrs93dvf/4BILJZ5Xq34Es2Np1Y96lc/5dc2875add15ee12d2f960ceb51776fe/amanda.png" alt="amanda"/>
            </Box>
            <Box className={cx(classes.column, classes.right)}>
                <Typography variant="h2">{t('home.careerSpecialist.header')}</Typography>
                <Typography variant="body1">
                    {t('home.careerSpecialist.description')}
                </Typography>
                <Box>
                    <LinkButton variant="outlined">{t('home.careerSpecialist.readMore')}</LinkButton>
                </Box>
            </Box>
        </Box>
    )
}

export default CareerSpecialist

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
          flexDirection: 'column',
        },
    },
    column: {
        display: 'flex',
        padding: 20,
        flexDirection: 'column',
        flex: 1,
    },
    left: {
        background: theme.colors.neutral[5],
        borderRadius: 20,
        '& img': {
            width: '100%'
        }
    },
    right: {
        '& > p': {
            margin: '20px 0'
        },
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
          '& > div > a': {
              display: 'flex', 
              flex: 1
          }
        },
    }
}))