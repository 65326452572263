import { Box, Typography } from '@mui/material'
import FAQ from '@talentinc/gatsby-theme-ecom/components/FAQ'
import React from 'react'

interface Props {}

const items = [
    { id: 1, question: 'How much does TopResume cost?', answer: 'Lorem ipsum dolor sit amet' },
    { id: 2, question: 'Why should you trust us to write your resume?', answer: 'Lorem ipsum dolor sit amet' },
    { id: 3, question: 'Why are we rated as one of the best resume writing companies?', answer: 'Lorem ipsum dolor sit amet' },
    { id: 4, question: 'Is paying for a resume really worth it?', answer: 'Lorem ipsum dolor sit amet' },
    { id: 5, question: 'What is Affirm?', answer: 'Lorem ipsum dolor sit amet' },
]

function HomeFAQ(props: Props) {
    const {} = props

    return (
        <Box>
            <Typography variant="h2">Frequently asked questions</Typography>
            {items.map(({ id, question, answer }) => (
                <FAQ
                    contentful_id={id}
                    question={question}
                    answer={answer}
                />
            ))}
        </Box>
    )
}

export default HomeFAQ
