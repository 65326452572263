import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {}

const items = [
    { id: 1, title: 'Personalized documents', description: 'Get a modern resume that tells your career story and highlights your full potential', img: 'https://images.ctfassets.net/7thvzrs93dvf/4BILJZ5Xq34Es2Np1Y96lc/29c0a8e9fafd5649a127c8e68e8de99e/feature1.png'},
    { id: 2, title: '1 on 1 support', description: 'Get matched with a professional writer based on your industry and job goals', img: 'https://images.ctfassets.net/7thvzrs93dvf/4BILJZ5Xq34Es2Np1Y96lc/7a632644bd23c848d5882ae512b45e66/feature2.png'},
    { id: 3, title: 'Optimized keywords', description: 'Beat the HR algorithms that filter out 75% of job applications before they’re seen by employers', img: 'https://images.ctfassets.net/7thvzrs93dvf/4BILJZ5Xq34Es2Np1Y96lc/ce6a941089f062bbf0ad0f6894105f05/feature3.png'},
]

function Item ({ classes, item }) {
    return (
        <Box className={classes.item}>
            <Box className={classes.header}>
                <Typography variant="h3">{item.title}</Typography>
                <Typography variant="body2">{item.description}</Typography>
            </Box>
            <img src={item.img} />
        </Box>
    )
}

function NewFeatures(props: Props) {
    const {} = props

    const { classes, cx } = useStyles()
    const { t } = useTranslation() 

    return (
        <Box className={classes.container}>
            <Typography variant="h2">{t('home.newFeatures.header')}</Typography>
            <br/>
            <Typography variant="body1">{t('home.newFeatures.subtitle')}</Typography>
            <Box className={classes.row}>
                {items.map(item => <Item item={item} classes={classes} />)}
            </Box>
        </Box>
    )
}

export default NewFeatures

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: 20,
        marginTop: 32,
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'column',
        },
    },
    item: {
        background: theme.colors.neutral[5],
        alignItems: 'center',
        borderRadius: 20,
        textAlign: 'center',
        '& img': {
            width: '100%',
            height: 'auto'
        },
        color: theme.colors.neutral[60]
    },
    header: {
        padding: 32,
        textAlign: 'center',
    }
}))
