import { Box, Typography } from '@mui/material'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import useGetCareerResources from './useGetCareerResources'

interface Props {}

function CareerResources(props: Props) {
    const {} = props
    const { classes } = useStyles()
    const content = useGetCareerResources()
    const { t } = useTranslation() 

    return (
        <Box className={classes.container}>
            <Box className={classes.row}>
                <Box className={classes.headerItem}>
                    <Typography variant={'h1'}>{t('home.careerResources.header')}</Typography>
                    <br/>
                    <Typography variant={'body1'}>{t('home.careerResources.description')}</Typography>
                </Box>
                <Box className={classes.btnContainer}>
                    <Box>
                        <LinkButton variant={'outlined'}>{t('home.careerResources.viewCareerResources')}</LinkButton>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.contentRow}>
                {content.map((resource) => {
                    return (
                        <Box className={classes.item}>
                            <img src={resource.img}/>
                            <Box className={classes.row}>
                                <Typography variant={'h3'}>{resource.title}</Typography>
                                <Box className={classes.circle}/>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default CareerResources

const useStyles = makeStyles()((theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 30
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
    },
    headerItem: {
        flex: 1, display: 'flex', flexDirection: 'column',
        '& > div': {
            alignSelf: 'flex-end'
        }
    },
    btnContainer: {
        flex: 1, display: 'flex', flexDirection: 'column',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            marginTop: 20
        },
    },
    item: {
        flex: 1,
        '& img': {
            width: '100%',
            marginBottom: 10
        }
    },
    contentRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 30,
        gap: 30,
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            flexDirection: 'column',
        },
    }
}))
