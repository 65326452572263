import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React from 'react'

interface Props {}

const items = [
    { id: 1, description: 'Don\'t get caught job hunting: 5 ways to search in secret', img: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/cfa2d84096f411a9d35968fabc7383c2/featured1.png'},
    { id: 2, description: 'Where to Find Top-Tier Talent in 2024', img: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/c996a55bac2387ae38f32c581a7f0fdd/press2.png'},
    { id: 3, title: 'Optimized keywords', description: 'Beat the HR algorithms that filter out 75% of job applications before they’re seen by employers', img: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/cd0c63fee5cf3317e86281b619b19c37/press3.png'},
]

function Item ({ classes, item }) {
    return (
        <Box className={classes.item}>
            <Box>
                <img src={item.img} />
            </Box>
            <br/>
            <Typography variant="subtitle2">{item.description}</Typography>
        </Box>
    )
}

function PressHome(props: Props) {
    const {} = props

    const { classes, cx } = useStyles()

    return (
        <Box className={classes.container}>
            <Typography variant="h2">See where we have been featured</Typography>
            <Box className={classes.row}>
                {items.map(item => <Item item={item} classes={classes} />)}
            </Box>
        </Box>
    )
}

export default PressHome

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: 20,
        marginTop: 32,
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'column',
        },
    },
    item: {
        background: theme.colors.neutral[5],
        borderRadius: 20,
        padding: 32,
        flexDirection: 'column',
        flex: 1,
        display: 'flex',
        color: theme.colors.neutral[60]
    },
}))
