import React from 'react'
import RoundBackgroundBlock from '@talentinc/gatsby-theme-ecom/components/RoundBackgroundBlock/RoundBackgroundBlock'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import { RBBVariants } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import { Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import TRResumeUpload from '@talentinc/gatsby-theme-ecom/components/Widgets/TRResumeUpload'
import { useTranslation } from 'react-i18next'

interface Props {}

function HomeHero(props: Props) {
    const {} = props
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <RoundBackgroundBlock variant={RBBVariants.Custom}>
            <Box className={classes.container}>
                <Box className={classes.left}>
                    <Typography variant={'h1'}>
                        {t(`home.hero.header`)}
                    </Typography>
                    <Typography variant={'h3'}>
                        {t(`home.hero.subtitle`)}
                    </Typography>
                    <Box className={classes.btnContainer}>
                        <LinkButton href="/resume-writing">{t(`home.hero.btn`)}</LinkButton>
                    </Box>
                </Box>
                <Box className={classes.right}>
                    <Box>
                        <TRResumeUpload options={{ isV2: true, showMobileButton: true, mobileWidget: 'Upload Widget' }}/>
                    </Box>
                </Box>
            </Box>
        </RoundBackgroundBlock>
    )
}

export default HomeHero

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        gap: '6%',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
          flexDirection: 'column',
          gap: 0
        },
        '& h3': {
            marginTop: 20
        }
    },
    btnContainer: {
        marginTop: 32,
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            width: '100%',
            '& a': {
                width: '100%',
            }
        },
    },
    left: {
        display: 'flex',
        zIndex: 2,
        flexDirection: 'column',
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            textAlign: 'center'
          },
    },
    right: {
        zIndex: 2,
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            width: '100%'
          },
    }
}))